/* ==========================================================================
   Night theme skin
   ========================================================================== */

/* Original colors */
/*
$base-color: #252a34 !default;
$text-color: #eaeaea !default;
$accent-color: #00adb5 !default;
$background-color: #252a34 !default;
*/

$base-color: #05070a !default;
$text-color: #fbfbfc !default;
$accent-color: #e74c3c !default;
$primary-color: lighten(#2c3e50, 35%) !default;
$background-color: #05070a !default;
$navigation-hover-color: $accent-color !default;

$code-background-color: #fff !default;
$border-color: rgba($text-color, 0.5) !default;
$overlay-color: #fff !default;
